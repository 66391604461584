<template>
  <div class="main-box">
    <div class="farmDetail">
      <div class="detail-info">
        <label for="">
          نام انتخابی زمین
          <span style="color: red">*</span>
        </label>
        <input type="text" class="detail-info-el-input" v-model="model.title" />
      </div>
      <div class="detail-info">
        <label>
          تاریخ کشت
          <span style="color: red">*</span>
        </label>
        <date-picker
          color="var(--secondary)"
          class="detail-info-el"
          v-model="model.cultivationsDate"
        ></date-picker>
      </div>
      <div class="detail-info">
        <label for="">
          نوع محصول
          <span style="color: red">*</span>
        </label>
        <v-select
          dir="rtl"
          v-model="selectedProductType"
          :options="productTypesSource"
          placeholder="انتخاب نوع محصول..."
          class="detail-info-el"
        ></v-select>
      </div>
      <div class="detail-info">
        <label for="">
          نام محصول
          <span style="color: red">*</span>
        </label>
        <v-select
          dir="rtl"
          v-model="selectedProduct"
          :options="productsSource"
          placeholder="انتخاب محصول..."
          class="detail-info-el"
        ></v-select>
      </div>
      <div class="detail-info">
        <label for="">
          نوع کشت
          <span style="color: red">*</span>
        </label>
        <v-select
          dir="rtl"
          v-model="selectedCultivationsType"
          :options="cultivationsTypeSource"
          placeholder="انتخاب نوع کشت..."
          class="detail-info-el"
        ></v-select>
      </div>
      <div class="detail-info">
        <label for="">
          کشور
          <span style="color: red">*</span>
        </label>
        <v-select
          dir="rtl"
          v-model="selectedCountry"
          :options="countriesSource"
          placeholder="انتخاب کشور..."
          class="detail-info-el"
        />
      </div>
      <div class="detail-info">
        <label for="">
          استان
          <span style="color: red">*</span>
        </label>
        <v-select
          dir="rtl"
          v-model="selectedState"
          :options="statesSource"
          placeholder="انتخاب استان ها..."
          class="detail-info-el"
        ></v-select>
      </div>
      <div class="detail-info">
        <label for="">
          شهرستان
          <span style="color: red">*</span>
        </label>
        <v-select
          dir="rtl"
          v-model="selectedCity"
          :options="citySource"
          placeholder="انتخاب شهرستان ها..."
          class="detail-info-el"
        />
      </div>
      <div class="detail-info">
        <label for="">
          بخش
          <span style="color: red">*</span>
        </label>
        <textarea
          v-model="model.district"
          rows="3"
          class="detail-info-el-input"
        ></textarea>
      </div>
      <div class="detail-info">
        <label for="">
          روستا
          <span style="color: red">*</span>
        </label>
        <textarea
          v-model="model.village"
          rows="3"
          class="detail-info-el-input"
        ></textarea>
      </div>
      <button class="btn-edit-farm" @click="submit()">ثبت تغییرات</button>
    </div>
    <div class="farmMap">
      <vue-element-loading
        :active="loading"
        :text="loadingText"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
      <div id="map" ref="map" />
      <geocode-search @selectedGeocode="geocodeFind"></geocode-search>

      <div id="menu"></div>
      <div class="calculation-box" v-if="rounded_area">
        مساحت: {{ rounded_area }} هکتار
      </div>
    </div>
  </div>
</template>

  <script>
import { mapActions } from "vuex";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import { ZoomControl } from "mapbox-gl-controls";
import * as turf from "@turf/turf";
import GeocodeSearch from "../../components/map/GeocodeSearch.vue";
import { calculateBboxService } from "../../services/calculateBbox.service";
import { formatPolygonService } from "../../services/formatPolygon.service";
import polyGonStructurepolygon from "../../store/data/polygon.json";
import polyGonStructure from "../../store/data/polygonFeature.json";
import cultivationsType from "../../store/data/enum/cultivationsType.json";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "editFarm",
  props: ["farmId"],
  components: {
    vSelect,
    GeocodeSearch,
  },
  data() {
    return {
      rounded_area: null,
      loading: false,
      loadingText: "",
      map: null,
      polygonCenter: null,
      selectedFarmArea: 0,
      request: {
        layerId: null,
        date: null,
        box: null,
        polygon: [],
        formatedPolygon: null,
      },

      cultivationsTypeSource: cultivationsType,
      selectedCultivationsType: "",
      countriesSource: [],
      selectedCountry: "",
      statesSource: [],
      selectedState: "",
      citySource: [],
      selectedCity: "",
      productTypesSource: [],
      selectedProductType: "",
      productsSource: [],
      selectedProduct: "",
      model: {
        id: 0,
        title: "",
        productTypeId: null,
        productId: null,
        cultivationsType: null,
        cultivationsDate: null,
        stateId: null,
        cityId: null,
        village: null,
        district: null,
        areaString: null,
        coordinationString: null,
      },
    };
  },
  watch: {
    selectedProductType: function () {
      this.productsSource = [];
      this.selectedProduct = "";
      if (this.selectedProductType.id > 0) {
        this.getProductWithType(this.selectedProductType.id);
      }
    },
    selectedCountry: function () {
      this.statesSource = [];
      this.selectedState = "";
      this.citySource = [];
      this.selectedCity = "";

      if (this.selectedCountry.id > 0) {
        this.GetProvinceWithCountryId(this.selectedCountry.id);
      }
    },
    selectedState: function () {
      this.citySource = [];
      this.selectedCity = "";
      if (this.selectedState.id > 0) {
        this.GetCityWithProvinceId(this.selectedState.id);
      }
    },
  },
  methods: {
    ...mapActions("farm", [
      "Get",
      "GetAllProductType",
      "GetProductWithType",
      "GetAllCountry",
      "GetProvinceWithCountry",
      "GetCityWithProvince",
      "updateFarm",
    ]),

    addPolygonLayer() {
      let geojason = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [this.request.polygon],
        },
      };
      if (this.map.getSource("maine")) {
        if (this.map.getLayer("maine")) {
          this.map.removeLayer("maine");
        }
        this.map.removeSource("maine");
      }
      if (this.map.getSource("indexImage")) {
        if (this.map.getLayer("indexImage-layer")) {
          this.map.removeLayer("indexImage-layer");
        }
        this.map.removeSource("indexImage");
      }
      this.map.addSource("maine", {
        type: "geojson",
        data: geojason,
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "maine",
        type: "fill",
        source: "maine", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#0080ff", // blue color fill
          "fill-opacity": 0.5,
        },
      });
    },
    calculateBbox(polygon) {
      this.request.box = calculateBboxService.calculateBox(polygon);
    },
    async loadFarmMap(cordinates) {
      const newArray = [cordinates];
      polyGonStructure.features[0].geometry.coordinates = newArray;

      let farmArea = turf.area(polyGonStructure.features[0].geometry);
      this.selectedFarmArea = Intl.NumberFormat("fa").format(
        (farmArea / 10000).toFixed(2)
      );
      this.request.polygon = cordinates;
      let polygon = turf.polygon([this.request.polygon]);
      let center = turf.centerOfMass(polygon);
      this.polygonCenter = center["geometry"]["coordinates"];
      this.request.formatedPolygon = formatPolygonService.polygonreFormat(
        this.request.polygon
      );
      this.calculateBbox(this.request.polygon);
      this.addPolygonLayer();
    },
    consolArea() {
      const data = this.draw.getAll();
      if (data.features.length > 0) {
        let area = turf.area(data.features[0].geometry);
        this.mapCenter = turf.centerOfMass(
          data.features[0].geometry
        ).geometry.coordinates;
        // restrict to area to 2 decimal points
        this.rounded_area = Intl.NumberFormat("fa").format(
          (area / 10000).toFixed(2)
        );
        this.isSelectedArea = true;
        this.coordinates = data.features[0].geometry.coordinates[0];
      } else {
        this.mapCenter = null;
        this.rounded_area = null;
        this.isSelectedArea = false;
        this.coordinates = [];
      }
    },
    addAreaTitle() {
      let areaTitle = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              description: this.rounded_area + "هکتار",
              icon: "theatre-15",
            },
            geometry: {
              type: "Point",
              coordinates: this.mapCenter,
            },
          },
        ],
      };
      if (this.isAddSource) {
        this.map.getSource("areaTitle").setData({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                description: this.rounded_area + "هکتار",
                icon: "theatre-15",
              },
              geometry: {
                type: "Point",
                coordinates: this.mapCenter,
              },
            },
          ],
        });
      } else {
        this.map.addSource("areaTitle", {
          type: "geojson",
          data: areaTitle,
        });
        this.isAddSource = true;
      }
    },
    geocodeFind(coords) {
      this.map.fitBounds([
        [coords[2], coords[0]], // southwestern corner of the bounds
        [coords[3], coords[1]], // northeastern corner of the bounds
      ]);
    },

    async fetchData() {
      this.loading = true;
      await this.getAllProductType();
      await this.getAllCountry();
      this.loadingText = "دریافت اطلاعات زمین";
      var result = await this.Get({ id: this.farmId });
      this.loadingText = "دریافت اطلاعات نقشه";
      this.model.id = result.id;
      this.model.title = result.title;
      this.model.productTypeId = result.productTypeId;
      this.model.productId = result.productId;
      this.model.cultivationsType = result.cultivationsType;
      this.model.cultivationsDate = result.cultivationsDate;
      this.model.countryId = result.countryId;
      this.model.stateId = result.stateId;
      this.model.cityId = result.cityId;
      this.model.district = result.district;
      this.model.village = result.village;
      this.selectedCountry = this.countriesSource.filter((item) => {
        return item.id == result.countryId;
      })[0];
      await this.GetProvinceWithCountryId(result.countryId);
      this.selectedState = this.statesSource.filter((item) => {
        return item.id == result.stateId;
      })[0];
      await this.GetCityWithProvinceId(result.stateId);
      this.selectedCity = this.citySource.filter((item) => {
        return item.id == result.cityId;
      })[0];
      this.selectedProductType = this.productTypesSource.filter((item) => {
        return item.id == result.productTypeId;
      })[0];

      await this.getProductWithType(result.productTypeId);
      this.selectedProduct = this.productsSource.filter((item) => {
        return item.id == result.productId;
      })[0];
      this.selectedCultivationsType = this.cultivationsTypeSource.filter(
        (item) => {
          return item.value == result.cultivationsType;
        }
      )[0];
      await this.loadFarmMap(result.cordinates);
      this.map.resize();
      this.map.fitBounds(this.request.box, {
        padding: 260,
        animate: true,
        maxZoom: 18,
        duration: 5000,
      });
      this.loading = false;
    },
    async getAllProductType() {
      var result = await this.GetAllProductType({ pageNumber: 0, pageSize: 0 });
      this.productTypesSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async getProductWithType(typeId) {
      var result = await this.GetProductWithType({ productTypeId: typeId });
      this.productsSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async getAllCountry() {
      var result = await this.GetAllCountry({ pageNumber: 0, pageSize: 0 });
      this.countriesSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async GetProvinceWithCountryId(countryId) {
      var result = await this.GetProvinceWithCountry({ countryId: countryId });
      this.statesSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async GetCityWithProvinceId(provinceId) {
      var result = await this.GetCityWithProvince({ stateId: provinceId });
      this.citySource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },

    async submit() {
      this.loading = true;

      let newCenterString;
      let newAreaString;
      if (this.coordinates) {
        const newArray = [this.coordinates];
        polyGonStructurepolygon.geometry.coordinates = newArray;
        var features = turf.points(this.coordinates);
        var center = turf.center(features);
        newCenterString = JSON.stringify(center, null, 0);
        newAreaString = JSON.stringify(polyGonStructurepolygon, null, 0);
      }
      let farm = {
        id: this.model.id,
        title: this.model.title,
        cultivationsDate: this.model.cultivationsDate,
        productTypeId: this.selectedProductType.id,
        productId: this.selectedProduct.id,
        cultivationsType: this.selectedCultivationsType.value,
        stateId: this.selectedState.id,
        cityId: this.selectedCity.id,
        village: this.model.village,
        district: this.model.district,
        coordinationString: newCenterString,
        areaString: newAreaString,
      };
      let result = await this.updateFarm({ farm });
      if (result.succeeded) {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.data.message} <br /><br />`,
          type: "success",
        });
        this.$emit("closeModal");
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      this.loading = false;
    },
  },

  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiaGFtaWRrYXplbXphZGVoIiwiYSI6ImNrcTJnNmY4NTAzczMybm85bDNvOGJ4NTYifQ.uEM3b50GGJ0aCbsXL3-O7A";
    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {//loaded
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true // Lazy load the plugin
      );
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/hamidkazemzadeh/ckq2sozke25hf17qh16mdszbh",
      // center: [59.55679711007316, 36.316138357670575], // starting position [lng, lat]
      center: [53.12609431323281, 33.59332186046887], // starting position [lng, lat]
      zoom: 5, // starting zoom
      showZoom: true,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
        zoom: true,
      },
      defaultMode: "draw_polygon",
    });
    this.map.addControl(this.draw, "top-right");
    this.map.addControl(new ZoomControl(), "top-left");
    this.map.on("draw.create", this.consolArea);
    this.map.on("draw.create", this.addAreaTitle);
    this.map.on("draw.delete", this.consolArea);
    this.map.on("draw.update", this.consolArea);
    this.map.on("load", function () {});
    this.fetchData();
  },
};
</script>

  <style scoped>
body {
  margin: 0;
  padding: 0;
}
.main-box {
  width: 90vw;
  height: 90vh;
  overflow-y: auto;
  position: relative;
}
.farmDetail {
  float: right;
  width: 26vw;
  height: 90vh;
}
.farmMap {
  float: right;
  width: 63vw;
  height: 90vh;
  position: relative;
}
#map {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
#menu {
  position: absolute;
  left: 50%;
  /* background: #efefef; */
  font-family: "Open Sans", sans-serif;
  width: 15rem;
  margin-left: -7.5rem;
}
.calculation-box {
  height: 75px;
  width: 150px;
  position: absolute;
  bottom: 40px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
}
.penLable {
  position: absolute;
  top: 3.1rem;
  right: 2.5rem;
  background-color: #f5f5f5;
  padding: 0.2rem;
  text-align: center;
}
.box {
  background-color: var(--surface-e);
  text-align: center;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.switchBtn.on {
  background-color: #f5f5f5;
  border-color: bisque;
  min-width: 5.2rem;
  border: 1px solid #f5f5f5;
  box-shadow: 0;
  padding: 0.4rem;
  margin-top: 0.5rem;
}
.switchBtn {
  background-color: #ccc;
  min-width: 5.2rem;
  border: 1px solid #ccc;
  box-shadow: 0;
  padding: 0.4rem;
  margin-top: 0.5rem;
}

.switchBtnNext {
  min-width: 10.4rem;
  box-shadow: 0;
  padding: 0.1rem;
  margin-top: 0.1rem;
}
#mainMenuBg {
  height: 2rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
}
#mainMenu {
  height: 2rem;
  width: 100%;
  position: absolute;
  bottom: 4rem;
  background-color: transparent;
  padding: 15px;
  text-align: center;
}
a.mapboxgl-ctrl-logo {
  visibility: hidden;
}

.detail-info {
  float: right;
  clear: both;
  width: 98%;
  margin: 0.4vw 0px 0px 0px;
  padding: 0px 0.3em;
  height: 7.5vh;
}
.detail-info label {
  float: right;
  clear: right;
  font-family: "Dirooz";
  color: darkgreen;
}
.detail-info-el {
  float: right;
  clear: right;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
}
.detail-info-el-input {
  float: right;
  clear: right;
  width: 100%;
  height: 4vh;
  background-color: #fff;
  border-radius: 0px;
  border: none;
  border: 1px solid #bbb;
  height: 3vh;
}

.btn-edit-farm {
  float: right;
  width: 80%;
  margin: 9%;
  border: none;
  background-color: yellowgreen;
  box-shadow: 0px 5px 15px -7px #000;
}
</style>
<style>

</style>
