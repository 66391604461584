<template>
  <div class="main-card h-100 gap-20 d-flex flex-column">
    <CRow class="bodyItem gap-10">
      <CCol col="12" class="bodyItemValue y-center-g-5">
        <img
          :src="productImage"
          @error="
            () => (productImage = require(`@/assets/img/farmImageMapBox.jpg`))
          "
          class="productImage"
          alt=""
        />
        <h4>{{ title }}</h4>
      </CCol>
      <CCol col="12" class="bodyItemValue">
        <span>نوع محصول :</span>
        <span>{{ productType }}</span>
      </CCol>
      <CCol col="12" class="bodyItemValue">
        <span>مساحت :</span>
        <span class="itemFarmArea">{{ farmArea }} هکتار</span>
      </CCol>
      <CCol col="12" class="bodyItemValue">
        <span>نوع کشت :</span>
        <span>{{ cultivationsType }}</span>
      </CCol>
      <hr class="m-2 w-100" />
      <CCol col="12" class="bodyItemValue">
        <CIcon name="cilMap" class="ml-2"></CIcon>
        <span>{{ address }}</span>
      </CCol>
    </CRow>
    <CRow class="gap-10" :class="selectedItemId == id ? 'btnBox' : ''">
      <CDropdown placement="bottom-end" direction="dropup">
        <template #toggler-content>عملیات</template>
        <CDropdownItem
          v-if="canEdit"
          class="y-center-g-5"
          @click="loadSubFormFarm('farmEdit', 'ویرایش اطلاعات زمین')"
        >
          <i class="fa fa-pencil" aria-hidden="true"></i>
          <span>ویرایش</span>
        </CDropdownItem>
        <CDropdownItem
          v-if="canDelete"
          class="y-center-g-5"
          @click="deleteItem()"
        >
          <i class="fa fa-trash" aria-hidden="true"></i>
          <span>حذف</span>
        </CDropdownItem>
        <CDropdownItem class="y-center-g-5" @click="dietPage()">
          <i class="fa fa-cutlery" aria-hidden="true"></i>
          <span>برنامه غذایی</span>
        </CDropdownItem>
        <CDropdownItem class="y-center-g-5" @click="advicePage()">
          <i class="fa fa-graduation-cap" aria-hidden="true"></i>
          <span>توصیه های زمین</span>
        </CDropdownItem>
      </CDropdown>
      <CButton
        class="farmItemBtn flex-grow-1 btn-View"
        v-c-tooltip="{
          content: 'جزئیات',
          placement: 'top',
        }"
        @click="detailItem()"
      >
        <i class="fa fa-eye" aria-hidden="true"></i>
        <span> جزئیات </span>
      </CButton>
      <CButton
        v-if="archiveFarm"
        class="farmItemBtn btn-danger"
        @click="detailArchive()"
      >
        <i class="fa fa-reply" aria-hidden="true"></i>
        <span> خروج از آرشیو </span>
      </CButton>
    </CRow>
    <VueModal
      v-model="modelShowState"
      :title="modalTitle"
      wrapper-class="animate__animated animate__faster"
      modal-class="drawer"
      in-class="animate__fadeInRight"
      out-class="animate__fadeOutRight"
    >
      <component
        @closeModal="closeModal"
        v-if="subForm != null"
        v-bind:is="subForm"
        :farmId="id"
      />
    </VueModal>
  </div>
</template>

<script>
import DietPlan from "../../containers/DietPlan";
import farmEdit from "./farmEdit";
import { apiUrlRoot } from "../../constants/config";
import { mapActions } from "vuex";

export default {
  name: "farmItem",
  props: {
    itemModel: {
      type: Object,
    },
    selectedItemId: {
      type: Number,
    },
    archiveFarm: {
      type: Boolean,
    },
  },
  components: {
    DietPlan,
    farmEdit,
  },
  data() {
    return {
      modelShowState: false,
      modalTitle: "",
      subForm: null,
      id: 0,
      title: "",
      productType: "",
      cultivationsType: "",
      address: "",
      canEdit: true,
      canDelete: true,
      farmArea: 0,
      productImage: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    ...mapActions("dashboard", [
      "GetFarmDietPlanByFarmId",
      "GetFarmAdviceByFarmId",
      "GetAdviceInfoById",
    ]),
    fetchData() {
      this.id = this.itemModel.farmId;
      this.title = this.itemModel.title;
      this.productType = this.itemModel.product;
      this.cultivationsType = this.itemModel.cultivationsTypeTitle;
      this.address = this.itemModel.address;
      this.canEdit = this.itemModel.canEdit;
      this.canDelete = this.itemModel.canDelete;
      this.farmArea = this.itemModel.farmArea;
      this.productImage = `${apiUrlRoot}ProductImage/${this.itemModel.productImage}`;
    },
    deleteItem() {
      this.$emit("deleteFarmItem", this.canDelete, this.id);
    },
    detailArchive(){
      this.$emit("detailArchiveItem", this.id);
    },
    detailItem() {
      this.$emit("detailFarmItem", this.id);
    },
    loadSubFormFarm(subFormName, subFormTitle) {
      this.subForm = subFormName;
      this.modalTitle = subFormTitle;
      this.modelShowState = true;
    },
    async advicePage() {
      const result = await this.GetFarmAdviceByFarmId({
        farmId: this.id,
      });
      if (result.succeeded) {
        if (result.data.length > 0) {
          await this.$router.push({
            name: "توصیه های زمین",
            params: {
              adviceId: result.data[0].id,
            },
          });
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `توصیه ای یافت نشد`,
            type: "error",
          });
        }
      }
    },
    async dietPage() {
      const result = await this.GetFarmDietPlanByFarmId({
        farmId: this.id,
      });
      if (result.succeeded) {
        if (result.data.length > 0) {
          await this.$router.push({
            name: "برنامه غذایی زمین",
            params: {
              dietImage: result.data[0].image,
            },
          });
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `برنامه غذایی برای این زمین یافت نشد`,
            type: "error",
          });
        }
      }
    },
    closeModal() {
      this.modelShowState = false;
      this.$emit("reloadList");
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.bodyItemValue {
  width: 100%;
}

.bodyItemValue h5 {
  font-size: 14px;
  padding-right: 4px;
}

.farmItemBtn {
  border: none;
}

.itemFarmArea {
  font-size: 1rem;
  margin: 0;
  text-align: center;
  width: 100%;
  height: fit-content;
}

.btn-Edit {
  background-color: rgb(52, 116, 253);
  right: 20%;
}

.btn-Delete {
  background-color: red;
  right: 34%;
}

.btn-View {
  background-color: var(--primary-light);
  color: #fff;
}

.productImage {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
</style>
